import { SIGNIN_USER, SIGNIN_USER_FAILURE, SIGNIN_USER_SUCCESS } from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  error: null,
};

const authActions = (state = initialState, action,) => {
  switch (action.type) {
    case SIGNIN_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SIGNIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case SIGNIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authActions;
