import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}accounts`} component={asyncComponent(() => import('./AccountPage'))}/>
      <Route path={`${match.url}platforms`} component={asyncComponent(() => import('./PlatformPage'))}/>
      <Route path={`${match.url}environments`} component={asyncComponent(() => import('./EnvironmentSettings'))}/>
    </Switch>
  </div>
);

export default App;
