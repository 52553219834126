import { SIGNIN_USER, SIGNIN_USER_FAILURE, SIGNIN_USER_SUCCESS } from '../../constants/ActionTypes';
import { httpClient } from '../../util/Api';

const loginRequest = () => ({
    type: SIGNIN_USER,
});

const loginSuccess = (user) => ({
    type: SIGNIN_USER_SUCCESS,
    payload: user,
});

const loginFailure = (error) => ({
    type: SIGNIN_USER_FAILURE,
    payload: error,
});
const adminBaseUrl = process.env.REACT_APP_API;

export const login = (credentials) => {
    return async (dispatch) => {
        dispatch(loginRequest());
        try {
            const response = await httpClient.post(`${adminBaseUrl}/auth/login`, credentials);
            const token = response?.data.token;
            const saveUser = response?.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(saveUser));
            const user = response?.data;
            if (token) {
                dispatch(loginSuccess(user));
                return user;
            } else {
                dispatch(loginFailure('Login failed'));
            }
        } catch (error) {
            dispatch(loginFailure(error.message));
        }
    };
};

export const checkAuthState = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        if (token) {
            const {user} = JSON.parse(localStorage.getItem('user'));
            if (user) {
                dispatch(loginSuccess(user));
            }
        }
    };
};